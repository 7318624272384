import React, { useState } from "react";
import cancel from "../images/iconfinder-basics-22-296812.svg";
import $ from "jquery";
import twitterM from "../images/twitter-mobile.svg";
import twitterMiceblue from "../images/twitter-home-ice-blue.svg";
import mailM from "../images/mail-mobile.svg";
import mailMiceblue from "../images/mail-home-ice-blue.svg";
import callM from "../images/call-mobile.svg";
import callMiceblue from "../images/call-home-ice-blue.svg";
import linkedInM from "../images/linkedIn-mobile.svg";
import linkedInMiceblue from "../images/linkedIn-ice-blue.svg";
import message_sent from "../images/ic-message-sent.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

function StartYourProject({ reset }) {
  let history = useHistory();
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState("+966");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [description, setDescription] = useState("");
  const [emailVMessage, setEmailVMessage] = useState("|");
  const [projectFor, setProjectFor] = useState("0");
  const [budget, setBudget] = useState("0");

  const handleErrorMessage = (field) => {
    $(
      "#projectForm input, #projectForm textarea, #projectForm select"
    ).removeClass("error");
    $(".errorMessage").addClass("d-none");
    $(`#${field}`).addClass("error");
    $(`.${field}-error`).removeClass("d-none");
  };

  const submitForm = (e) => {
    e.preventDefault();
    var str = email.split("@").slice(1);
    var notAllowedDomains = [
      "hotmail.com",
      "gmail.com",
      "outlook.com",
      "icloud.com",
      "yahoo.com",
    ];

    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
    const countrycodeformat = /^(\+?\d{1,5})$/;

    if (fullName === "") {
      handleErrorMessage("fullName");
    } else if (company === "") {
      handleErrorMessage("company");
    } else if (projectFor === "0") {
      handleErrorMessage("projectFor");
    } else if (budget === "0") {
      handleErrorMessage("budget");
    } else if (
      countryCode === "" ||
      !countryCode.match(countrycodeformat) ||
      countryCode === "0"
    ) {
      handleErrorMessage("countryCode");
    } else if (mobileNumber === "" || mobileNumber.length < 9) {
      handleErrorMessage("mobileNumber");
    } else if (
      email === "" ||
      !email.match(mailformat) ||
      $.inArray(str[0], notAllowedDomains) !== -1
    ) {
      if ($.inArray(str[0], notAllowedDomains) !== -1) {
        setEmailVMessage(t("Work email is invalid"));
      } else {
        setEmailVMessage(t("Email is invalid"));
      }
      handleErrorMessage("email");
    } else if (description === "") {
      handleErrorMessage("description");
    } else if (fullName !== "" && mobileNumber !== "" && email !== "") {
      handleErrorMessage();
      handleActiveSection("sentSuccesfullySection");
    }
  };

  const handleButton = (type, section) => {
    if (section === "startYourProject") {
      reset();
      $(".startYourProjectNav a").removeClass("on").eq(1).addClass("on");
      $(".startYourProject .startYourProjectSection").eq(0).addClass("active");

      if (type === "open") {
        $(`.startYourProject`).addClass("active");
        $("body").css("overflow", "hidden");
      } else {
        setCountryCode("+966");
        setEmail("");
        setCompany("");
        setFullName("");
        setMobileNumber("");
        setDescription("");
        setProjectFor("0");
        setBudget("0");
        $(`.startYourProject`).removeClass("active");
        $("body").css("overflow", "auto");

        $(
          "#projectForm input, #projectForm textarea, #projectForm select"
        ).removeClass("error");
        $("#projectForm span").addClass("d-none");
      }
    } else {
      $(".startYourProject").removeClass("active");
      if (type === "home") {
        $.scrollTo("#homeSection", 500);
      }
      $("body").css("overflow", "auto");
      $(`.${section}`).toggleClass("active");
    }
  };

  const handleActiveSection = (id) => {
    $(".startYourProjectSection").removeClass("active");

    if (id === "sentSuccesfullySection") {
      let submitProject = $("#projectForm").serializeArray();

      var today = new Date();
      var date = `${today.getFullYear()}/${("0" + (today.getMonth() + 1)).slice(
        -2
      )}/${("0" + today.getDate()).slice(-2)} ${("0" + today.getHours()).slice(
        -2
      )}:${("0" + today.getMinutes()).slice(-2)}`;

      submitProject.push(
        {
          name: "Date",
          value: date,
        },
        {
          name: "ProjectFor",
          value: projectFor,
        },
        {
          name: "Budget",
          value: budget,
        }
      );

      ReactGA.ga("send", {
        hitType: "event",
        eventCategory: "Start your project",
        eventAction: "Submit",
        eventLabel: `Name:${submitProject[0].value} 
                - Employer:${submitProject[1].value} 
                - Project For: ${projectFor}
                - Project budget: ${budget}
                - Email:${submitProject[4].value} 
                - Mobile:${submitProject[3].value}${submitProject[2].value} 
                - Brief:${submitProject[5].value} 
                - Date:${date}`,
      });

      const url =
        "https://script.google.com/macros/s/AKfycbwmoJ0G4lWevHVPdKP3Oi3ut_P-x9_PvF1xBJXO5-nbqMTbDvs/exec";

      $.ajax({
        url: url,
        method: "GET",
        dataType: "json",
        data: submitProject,
      });

      let text = `
            *الإسم:*
            ${submitProject[0].value}\n
            *الشركة/جهة العمل:*
            ${submitProject[1].value}\n
            * المشروع خاص بـ:*
            ${projectFor}\n
            *ميزانية المشروع:*
            ${budget}\n
            *البريد الإلكتروني:*
            ${submitProject[4].value}\n
            *رقم الجوال:*
            ${submitProject[3].value}-${submitProject[2].value}\n
            *مقدمة عن المشروع:*
            ${submitProject[5].value}\n
            *تاريخ الطلب:*
            ${date}
            `;

      var apiUrl = "https://slack.com/api/chat.postMessage";
      const token = process.env.REACT_APP_TOKEN;
      const channel = process.env.REACT_APP_CHANNEL;

      $.ajax({
        data: {
          token: token,
          channel: channel,
          text: text,
        },
        dataType: "text",
        type: "POST",
        url: apiUrl,
        error: function (xhr, status, error) {
          console.log("error: " + error);
        },
      });

      $(`#${id}`).addClass("active");
      handleButton("startYourProject");
    }
  };

  return (
    <div>
      <div className="startYourProject">
        <div className="socialMedia">
          <ul>
            <li>
              <a
                href="http://twitter.com/aqwas_sa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitterM} alt="call icon" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/aqwas"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedInM} alt="linkedIn icon" />
              </a>
            </li>
            {/* <li><a href="tel:+966539027927"><img src={callM} alt="twitter icon" /></a></li> */}
          </ul>
        </div>

        <img
          src={cancel}
          alt=""
          onClick={() => handleButton("close", "startYourProject")}
          className="cancel"
        />
        <div className="startYourProjectSection active">
          <div className="infoSection">
            {/* <p>{t("Start your project")}</p> */}
            <div className="main">
              <form id="projectForm" onSubmit={submitForm}>
                <h1>{t("Start your project")}</h1>
                <div className="d-flex">
                  <div>
                    <input
                      type="text"
                      name="FullName"
                      placeholder={t("Name")}
                      id="fullName"
                      className="input"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <span className="errorMessage d-none fullName-error">
                      {t("Please enter your name")}
                    </span>
                  </div>
                </div>
                <div>
                  <input
                    type="text"
                    name="Company"
                    placeholder={t("Employer")}
                    className="input"
                    id="company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                  <span className="errorMessage d-none company-error">
                    {t("Please enter employer")}
                  </span>
                </div>
                <div className="flex">
                  <div>
                    <select
                      id="projectFor"
                      onChange={(e) => setProjectFor(e.target.value)}
                    >
                      <option value="0" defaultValue>
                        {t("ProjectFor")}
                      </option>
                      <option value={t("Company")}>{t("Company")}</option>
                      {/* <option value={t("Individual")}>{t("Individual")}</option> */}
                      <option value={t("Government")}>{t("Government")}</option>
                    </select>
                    <span className="errorMessage d-none projectFor-error">
                      {t("Required")}
                    </span>
                  </div>

                  <div>
                    <select
                      id="budget"
                      onChange={(e) => setBudget(e.target.value)}
                    >
                      <option value="0" defaultValue>
                        {t("Budget")}
                      </option>
                      <option value={t("Budget1")}>{t("Budget1")}</option>
                      <option value={t("Budget2")}>{t("Budget2")}</option>
                      <option value={t("Budget3")}>{t("Budget3")}</option>
                      <option value={t("Budget4")}>{t("Budget4")}</option>
                    </select>
                    <span className="errorMessage d-none budget-error">
                      {t("Required")}
                    </span>
                  </div>
                </div>

                <div className="d-flex mobileNumber">
                  <div>
                    <input
                      type="number"
                      name="MobileNumber"
                      placeholder={t("Mobile number")}
                      id="mobileNumber"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                    <span className="errorMessage d-none mobileNumber-error">
                      {t("Mobile number is invalid")}
                    </span>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="CountryCode"
                      placeholder="+966"
                      id="countryCode"
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                    />
                    <span className="errorMessage d-none codeMessage countryCode-error">
                      {t("Code invalid")}
                    </span>
                  </div>
                </div>
                <div>
                  <input
                    type="text"
                    name="Email"
                    placeholder={t("Email")}
                    className="input"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  />
                  <span className="errorMessage d-none email-error">
                    {emailVMessage}
                  </span>
                  {/* <span className="errorMessage d-none">الرجاء ادخال بريد الكتروني خاص بالعمل</span> */}
                </div>
                <div>
                  <textarea
                    name="IntroductionToProject"
                    placeholder={t("Description")}
                    className="textarea-input"
                    rows="3.5"
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                  <span className="errorMessage d-none">
                    {t("Please enter description")}
                  </span>
                </div>
                <button type="submit">{t("Send")}</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* ----------- sentSuccesfullySection ------------  */}
      <div className="sentSuccesfullySection" id="sentSuccesfullySection">
        <img
          src={cancel}
          alt=""
          onClick={() => handleButton("close", "sentSuccesfullySection")}
          className="cancel"
        />
        <div className="succesfullyContent">
          <div>
            <img src={message_sent} alt="" />
          </div>
          <p>
            {t(
              "Your request has been sent successfully and you will be replied soon"
            )}{" "}
          </p>
          <button
            onClick={() => {
              handleButton("home", "sentSuccesfullySection");
              history.push("/");
            }}
          >
            {t("Homepage")}
          </button>
          <div className="socialMedia">
            <ul>
              <li>
                <a
                  href="http://twitter.com/aqwas_sa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitterMiceblue} alt="call icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/aqwas"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedInMiceblue} alt="linkedIn icon" />
                </a>
              </li>
              <li>
                {/* <a href="tel:+966539027927">
                  <img src={callMiceblue} alt="twitter icon" />
                </a> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* ----------- end sentSuccesfullySection ------------  */}
    </div>
  );
}

export default StartYourProject;
