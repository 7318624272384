import React, { useEffect } from "react";
import $ from "jquery";
import aqwasLogo from "../images/aqwasLogo.svg";
import download from "../images/ic-download.svg";
import twitterM from "../images/twitter-mobile.svg";
import mailM from "../images/mail-mobile.svg";
import callM from "../images/call-mobile.svg";
import linkedInM from "../images/linkedIn-mobile.svg";

import AqwasPortfolio from "../Aqwas-Portfolio-Arabic.pdf";
import { useHistory, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import StartYourProject from "./StartYourProject";
import ReactGA from "react-ga";

const Navbar = ({ setLang, reset, handleButton }) => {
  let history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    $(document).on("scroll", function () {
      var $nav = $(".nav");
      $nav.toggleClass("scrolled", $(this).scrollTop() > $nav.height());
    });
  }, []);

  const toggleButton = () => {
    $(".menu-toggle, .nav, .nav .navLinks").toggleClass("on");
  };

  const removeClasse = () => {
    if (window.innerWidth < 1024) {
      $(".menu-toggle, .nav, .nav .navLinks").removeClass("on");
    }
  };

  return (
    <div>
      <div className="nav">
        <div
          className="logo col-md-3"
          id="home"
          onClick={() => {
            history.push("/");
            $(window).animate(
              {
                scrollTop: 0,
              },
              1000
            );
            removeClasse();
          }}
        >
          <img src={aqwasLogo} alt="aqwas logo" />
        </div>
        <div className="menu-toggle" onClick={toggleButton}>
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>

        <div className="navLinks col-md-6 p-0">
          <ul className="navLinksUl">
            <li>
              <a
                id="mainPage"
                onClick={() => {
                  history.push("/");
                  removeClasse();
                }}
              >
                {t("Home")}
              </a>
            </li>
            <li>
              <a
                id="newsNav"
                onClick={() => {
                  history.push("/news");
                  removeClasse();
                }}
              >
                {t("Latest News")}
              </a>
            </li>
            <li>
              <a
                href="https://aqwas.typeform.com/to/TvivSI"
                target="_blank"
                onClick={() => {
                  ReactGA.ga("send", {
                    hitType: "event",
                    eventCategory: "Jobs button",
                    eventAction: "click",
                  });
                }}
              >
                {t("Jobs")}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setLang(t("lang"));
                  removeClasse();
                }}
              >
                {t("Language")}
              </a>
            </li>
            <li className="socialMediaLi">
              <div className="downloadLinkDiv">
                <a
                  href={AqwasPortfolio}
                  target="_blank"
                  className="downloadLink"
                >
                  {t("Portfolio")}
                </a>
              </div>
              <ul>
                <li>
                  <a href="http://twitter.com/aqwas_sa" target="_blank">
                    <img src={twitterM} alt="call icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/aqwas"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedInM} alt="linkedIn icon" />
                  </a>
                </li>
                {/* <li><a href="tel:+966539027927"><img src={callM} alt="twitter icon" /></a></li> */}
              </ul>
            </li>
          </ul>
        </div>

        <div className="navLeft col-md-3 p-0">
          <div>
            <img src={download} alt="download icon" />
            <a
              href={AqwasPortfolio}
              download={AqwasPortfolio}
              target="_blank"
              onClick={() => {
                ReactGA.ga("send", {
                  hitType: "event",
                  eventCategory: "Portfolio button",
                  eventAction: "Download",
                });
              }}
            >
              {t("Portfolio")}
            </a>
          </div>
          <button
            onClick={() => {
              handleButton("startYourProject");
              ReactGA.ga("send", {
                hitType: "event",
                eventCategory: "Start your project",
                eventAction: "click",
              });
            }}
          >
            {t("Start your project")}
          </button>
        </div>
      </div>

      <StartYourProject reset={reset} />
    </div>
  );
};

export default Navbar;
