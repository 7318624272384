import React, { useState, useEffect } from 'react';
import './Bootstrabp.css';
import './App.scss';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import loadable from '@loadable/component'
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
// import SmoothScroll from './components/SmoothScroll'
import "./i18n";
import ReactGA from 'react-ga';
import 'aos/dist/aos.css'
ReactGA.initialize('UA-81722696-1');

const LandingPage = loadable(() => import('./components/LandingPage'))
const News = loadable(() => import('./components/News'))

function App() {

  const { i18n } = useTranslation();
  const [currentLang, setCuurrentLang] = useState('ar');

  // useEffect(() => {
  //   new SmoothScroll(document, 80, 14)
  // }, [])

  const setLang = (lang) => {
    i18n.changeLanguage(lang);
    if (lang === 'en') {
      $('.body').addClass('en')
      setCuurrentLang('en')
    } else {
      $('.body').removeClass('en')
      setCuurrentLang('ar')
    }
  }

  const reset = () => {
    $('#projectForm').trigger('reset');
  }

  const handleButton = (section) => {
    if (section === 'startYourProject') {
      reset();
      $('.startYourProjectNav a').removeClass('on').eq(1).addClass('on');
      $('.startYourProject .startYourProjectSection').eq(0).addClass('active');
    }
    $(`.${section}`).toggleClass('active');
    if ($(`.startYourProject`).hasClass('active')) {
      $('body').css('overflow', 'hidden');
    } else {
      $('body').css('overflow', 'auto');
    }
  }

  return (
    <Router>
      <div className="body" id="homeSection">
        <Navbar
          reset={reset}
          handleButton={handleButton}
          setLang={setLang}
        />

        <Switch>
          <Route exact path="/">
            <LandingPage
              reset={reset}
              handleButton={handleButton}
              currentLang={currentLang}
            />
          </Route>
          <Route path="/news" component={News} />
        </Switch>

      </div>
    </Router>
  );
}

export default App;
