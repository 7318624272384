import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    ar: {
      translations: {
        // Home
        "Main Statement 1": "نصنع",
        "Main Statement 2": "تسّهل حياتك",
        Products: "منتجات",
        Applications: "تطبيقات",
        Websites: "مواقع",
        Experience: "تجربة",
        Home: "الرئيسية",
        "Latest News": "آخر أخبارنا",
        Jobs: "الوظائف",
        Portfolio: "ملف التعريف",
        "Start your project": "ابدأ مشروعك",
        //
        "Our Vision": "رؤيتنا",
        "Our Vision Content":
          "أقواس هي شركة سعودية تسعى لتقديم حلول وتصاميم تقنية ذات جودة عالية وعالمية، بتجربة مستخدم رائعة! بدء من تطبيقات الهواتف والويب إلى مشاريع ذات طابع أكثر تعقيدا، وتجسد أعمال أقواس بكثير من التطبيقات والبرامج المتميزة التي مست الملايين حول العالم.",
        //
        "Our Services": "خدماتنا",
        "User Experience Design (UX)": "تصميم تجربة المستخدم UX",
        "User Experience Design (UX) description":
          "البحث والتحليل والتصميم لتجربة المستخدم من أهم الخدمات التي نبرع بها.",
        "User Interface Design (UI)": "تصميم واجهة المستخدم UI",
        "User Interface Design (UI) description":
          "التصميم والتحريك (Animation) لواجهة المستخدم بناء على هوية المنتج بشكل حديث وأنيق",
        "Mobile App Development": "تطوير تطبيقات الهاتف",
        "Mobile App Development description":
          "تطوير التطبيقات بشكل كامل بأفضل وأحدث التقنيات لكل من الـiOS والـAndroid.",
        "Web Development": "تطوير مواقع الويب",
        "Web Development description":
          "تطوير الأنظمة المبنية على تقنيات الويب الحديثة مثل البوابات ومواقع إدارة المحتوى والمتاجر وغيرها.",
        "Technical Enterprise Solutions": "حلول المشاريع التقنية",
        "Technical Enterprise Solutions description":
          "حلول متكاملة للمشاريع التقنية من كتابة المتطلبات والتعريف بها ومروراً بهندسة الهيكلة والبيانات ووصولاً إلى اختبار وإطلاق المنتج.",
        "Full-time Developers and Designers": "مطورين ومصممين متفرغين",
        "Full-time Developers and Designers description":
          "نوفر مطورين ومهندسي البرمجيات ومصممين بعقد دوري ليكونوا متفرغين بالكامل لمشروعك، ويكونوا تحت إدارة وتشغيل أقواس لضمان أعلى مستويات الجودة.",
        "Request the service": "اطلب الخدمة",
        //
        "How it works": "طريقتنا",
        Breakthrough: " الإنطلاقة",
        "Breakthrough description":
          "نبدأ كل مشروع جديد بحماس للتعرف على المتطلبات وفي هذه المرحلة تحديداً نسمع من شريكنا أو عملائه أكثر مما نبدى الآراء في محاولة جادة مننا لفهم المشكلة التي نرغب بحلها، وليس التركيز بالحلول مباشرة، فالبداية نركز على استيعاب الأهداف أكثر من وسيلة الحل.",
        "Research and Study": "البحث والدراسة",
        "Research and Study description":
          "بعد تحديد المشكلة والأهداف المرجوة من المشروع نبدأ بمرحلة البحث والدراسة لكافة الحلول الممكنة وتحليلها والبحث عن مشاكل شبيهة، والفكرة بهذه المرحلة هو جمع أكبر عدد من الحلول والأفكار المناسبة ومن ثم ترتيبها وتحديد المناسب منها لأخذها بعين الاعتبار في المرحلة القادمة.",
        Ideation: "تصدير الأفكار",
        "Ideation description":
          "بعد فهم أعماق المشكلة وتحديد الأهداف والبحث والتحليل نبدأ أجمل مراحل المشروع وهو تصدير الأفكار (Ideation) والذي نقوم به من خلال العصف الذهني وطرق علمية أخرى بهدف تصدير أكبر قدر ممكن من الأفكار، ومن ثم ترتيبهم حسب الأفضل والأقل تكلفة من ناحية التنفيذ.",
        Prototyping: "النموذج المبدئي",
        "Prototyping description":
          "هنا نبدأ أكثر مراحل المشروع حماساً، حيث نأخذ كل الأفكار والحلول التي خرجنا بها في المراحل السابقة ونطبقها على هيئة نموذج مبدئي واختبارها مع الفئات المستهدفة لتحديد أي الأفكار ستكون مجدية ويتم اعتمادها للمرحلة القادمة.",
        "User Experience and Interface Design":
          "تجربة المستخدم وتصميم الواجهات",
        "User Experience and Interface Design description":
          "نحّول في هذه المرحلة النموذج الأولي لتصميم نهائي ونحرص على خروجه طبقاً لأفضل ممارسات التصميم والإبداع العالمية، ويكون التصميم جذّاب ورائع، وبنهاية هذه المرحلة نخرج بملفات التصميم وتجربة المستخدم كاملة.",
        Development: "التطوير",
        "Development description":
          "نقوم في هذه المرحلة بتحديد خطة التطوير، بناء قاعدة البيانات، وتطوير النظام بأكمله على جميع المنصات (ويب، آي أو إس، أندرويد) ونحرص في هذه المرحلة على استخدام أحدث التقنيات واتباع الأساليب المعتمدة فيما يخص التطوير وذلك لضمان أعلى درجات الأمان.",
        "Quality Assurance": "ضمان الجودة",
        "Quality Assurance description":
          "بعد إكتمال تصميم وتطوير جميع الأنظمة نبدأ بمرحلة فحص الجودة وضمان خلو النظام من أي مشكلة، والتي نأخذها بكل جدية لأهميتها الشديدة.",
        Delivery: "التسليم",
        "Delivery description":
          "وأخيراً وليس آخراً، نسلم النظام للشريك ونطلقه بشكل رسمي للاستخدام ونحرص على إطلاق سلس وخالي من الأعطال.",
        //
        "Our Clients": "عملاؤنا",
        Jarir: "جرير",
        "Jarir description":
          "أحد أكبر الشركات المساهمة المدرجة في المملكة العربية السعودية. قمنا بالتعاون مع فريق جرير بعمل دراسة كاملة لتجربة المستخدم وإعادة تصميم الواجهات الخاصة بالتطبيق والمتجر الإلكتروني.",
        Mrsool: "مرسول",
        "Mrsool description":
          "مرسول للتوصيل، يقدم لك خدمة سهلة، وبأسعار مناسبة، وفوق كذا، رح تكون تجربتك في مرسول ممتعة،المواد. قمنا بدراسة سلوك المستخدم في مرسول ومن ثم تصميم التطبيقات الخاصة بالمستخدمين والمراسيل.",
        Sarahah: "صراحة",
        "Sarahah description":
          "صراحة يساعدك في التعرف على عيوبك ومزاياك عن طريق الحصول على نقد بناء من موظفيك وأصدقائك بسرية. قمنا بتصميم وتطوير التطبيقات بالكامل بنسختها الثانية والثالثة ووصل عدد المستخدمين لأكثر من مئة مليون مستخدم في صراحة.",
        "National Volunteer Portal": "البوابة الوطنية للتطوع",
        "National Volunteer Portal description":
          "تسعى وزارة العمل والتنمية الاجتماعية الى تحقيق مستهدفات برنامج التحول الوطني ٢٠٢٠ انطلاقاً من رؤية المملكة ٢٠٣٠ بتوفير بوابة وطنية للتطوع تخدم جميع الجهات والمتطوعين في المملكة. قمنا بتصميم وتطوير البوابة بالكامل بالإضافة للتطبيقات والموقع الإلكتروني الخاص بالمتطوعين.",
        "General Authority for Tourism and National Heritage":
          "الهيئة العامة للسياحة والتراث الوطني",
        "General Authority for Tourism and National Heritage description":
          "قمنا بالتعاون مع فريق الهيئة باستحداث الأنظمة الخاصة بالتراخيص السياحية في المملكة العربية السعودية وتسهيلها على المستثمرين.",
        Musaned: "مساند",
        "Musaned description":
          "مساند ”عبارة عن منصة إلكترونية ومنظومة جديدة ومتكاملة تسهل إجراءات الاستقدام بشكل غير مسبوق. قمنا بالتعاون مع فريق مساند بتصميم تجربة المستخدم والواجهات الخاصة بالتطبيق، كما قمنا أيضًا بتطوير التطبيقات الخاصة بالمستخدمين.",
        Sawah: "سواح",
        "Sawah description":
          "في سّواح نسعى لإعادة تعريف المدن بما يلائم ذائقتنا، والتي ندرك تماماً أنها تلائم ذائقتك. هنا ترتدي المدن بكل مرافقها حلّة جديدة وكأنه صباح العيد. في عالم تغطي المياه ثلثاه، سواح يغطي الثلث الباقي. قمنا بالتعاون مع فريق سواح بدراسة سلوك المستخدم وبناء عليه قمنا بتصميم تجربة المستخدم والواجهات الخاصة بالتطبيق الجديد.",
        //
        Staff: "فريق العمل",
        "About Staff":
          "لدينا فريق عمل شغوف من السعودية وشتى أنحاء العالم، متخصصين في أغلب المجالات التقنية والهندسية، فريق عمل يتكون من مهندسي برمجيات وجودة ومصممين مبدعين وغيرهم ممن يعمل بكل إبداع وتفاني ويضعون الجودة دائماً أولاً في منتجاتهم التي مست الملايين حول العالم.",
        //
        Aqwas: "أقواس",
        "Contact with us": "تواصل معنا",
        "The Company": "الشركة",
        Language: "English",
        lang: "en",
        //
        "Project field": "مجال المشروع",
        "Project type": "نوع المشروع",
        "Contact info": "وسيلة التواصل",
        Website: "موقع الكتروني",
        "Mobile App": "تطبيق جوال",
        "Technical consultations": "استشارة تقنية",
        "Interface design": "تصميم واجهة",
        "Another type": "نوع اخر",
        "Another field": "مجال اخر",
        Next: "التالي",
        Islami: "إسلامي",
        Commercial: "تجاري",
        Entertaining: "ترفيهي",
        Educational: "تعليمي",
        Food: "أكل وشرب",
        Game: "لعبة",
        Social: "تواصل",
        Personal: "شخصي",
        Name: "الاسم",
        "Mobile number": "رقم الجوال",
        "Country code": "الرمز الدولي",
        Email: "البريد الالكتروني الخاص بالعمل",
        Employer: "الشركة/جهة العمل",
        Description: "مقدمة عن المشروع",
        Mr: "السيد",
        Ms: "السيدة",
        Send: "إرسال",
        "Please enter your name": "يرجى إدخال الاسم",
        "Please enter employer": "يرجى إدخال جهة العمل",
        "Mobile number is invalid": "رقم الجوال غير صحيح",
        "Code invalid": "الرمز غير صحيح",
        "Work email is invalid": "الرجاء ادخال بريد الكتروني خاص بالعمل",
        "Email is invalid": "البريد الالكتروني غير صحيح",
        "Please enter description": "يرجى إدخال وصف عن المشروع",
        "Your request has been sent successfully and you will be replied soon":
          "تم إرسال طلبك بنجاح وسيتم الرد عليك قريباً بإذن الله",
        ProjectFor: "المشروع خاص بـ",
        Company: "شركة",
        Individual: "فرد",
        Government: "حكومة",
        Required: "مطلوب",
        Budget: "حجم المشروع",
        Budget1: "صغير (٢٠٠ ألف - ٤٠٠ ألف ريال)",
        Budget2: "متوسط (٤٠٠ ألف - ٦٠٠ ألف ريال)",
        Budget3: "كبير (٦٠٠ ألف - مليون ريال)",
        Budget4: "كبير جداً (أكثر من مليون ريال)",
        //
        Homepage: "الصفحة الرئيسية",
        "About Aqwas": "مين أقواس",
        About: "عن",
        //
        Body: `
        <html dir="rtl">
        <p>شكراً لك على اهتمامك وتواصلك معنا.</p>
        <p>الخدمات التي نقدمها مصممة للمؤسسات والمشاريع التي بلغت مرحلة متقدمة من النمو في السوق.</p>
        <p>
        في حال كنتم فرداً أو ممثلين لمشروع حديث،
        فاعتذر لعدم توافق خدماتنا مع متطلباتكم وأنصحكم بمراجعة دليل شركات تطوير البرمجيات التالي‪:‫
        </p>
        <br/>
        <p>
        ‬‬أتمنى أن تجدوا فيه ما يناسب متطلباتكم ويساهم في نجاحكم. شكراً‪.‬
        </p>
        <p>تحياتي،</p>
        </html>
        `,
      },
    },
    en: {
      translations: {
        // Home
        "Main Statement 1": "We create",
        "Main Statement 2": "that make your life easier.",
        Products: "products",
        Applications: "applications",
        Websites: "websites",
        Experience: "experience",
        Home: "Home",
        "Latest News": "Latest News",
        Jobs: "Jobs",
        Portfolio: "Portfolio",
        "Start your project": "Start your project",
        //
        "Our Vision": "Our Vision",
        "Our Vision Content":
          "Aqwas is a Saudi company that strives to provide high quality technical and design solutions on a world class standard and a great user experience! From web and smartphone applications to projects of a more complex nature, our creations tend to touch millions of users on a worldwide scale through various mediums.",
        //
        "Our Services": "Our Services",
        "User Experience Design (UX)": "User Experience Design (UX)",
        "User Experience Design (UX) description":
          "Research, analysis, and design regarding user experience are some of the most important services which we execute with excellence.",
        "User Interface Design (UI)": "User Interface Design (UI)",
        "User Interface Design (UI) description":
          "Designing User Interface animations based on any given brand’s identity in a sleek yet modern way is something we’re known for.",
        "Mobile App Development": "Mobile App Development",
        "Mobile App Development description":
          "Development of mobile apps by incorporating the latest cutting-edge techniques for both android and IOS.",
        "Web Development": "Web Development",
        "Web Development description":
          "Building systems such as portals, content management websites, and online stores amongst many others by integrating the most recent web-development methods",
        "Technical Enterprise Solutions": "Technical Enterprise Solutions",
        "Technical Enterprise Solutions description":
          "We come up with solutions for technical enterprises starting from allocating our client’s requirements and defining them prior to implementing structural and data engineering techniques in order to test and deploy the final product.",
        "Full-time Developers and Designers":
          "Full-time Developers and Designers",
        "Full-time Developers and Designers description":
          "Our team of contracted software developers, engineers, and designers are fully dedicated to your project, working under the management and supervision of Aqwas and ensuring the highest quality standards",
        "Request the service": "Request the service",
        //
        "How it works": "How it works",
        Breakthrough: "Breakthrough",
        "Breakthrough description":
          "We start each project with enthusiasm towards discovering your requirements. We tend to be receptive during this phase by  listening to our partners and their clients rather than give suggestions in a serious attempt of diagnosing any issues they may be facing, our focus isn’t goal oriented from the get go. We aim to absorb all the possibilities prior to assessing solutions.",
        "Research and Study": "Research and Study",
        "Research and Study description":
          "After identifying the issues and desired goals of any given project we initiate the phase of research and study regarding all possibilities then analyze and research similar cases. The purpose of this phase is the collection of the largest sum of solutions and ideas then pick the most suitable to be carried out to the next phase.",
        Ideation: "Ideation",
        "Ideation description":
          "After understanding the depths of the problem, allocate the goals and research it, we start the most aesthetically pleasing phase of a project called Ideation during which we delve into brainstorming and other scientific methods with the goal of conceiving the largest sum of ideas possible, sequencing them based on a hierarchical system focused on cost and implementation.",
        Prototyping: "Prototyping",
        "Prototyping description":
          "During this phase, a project’s enthusiasm levels are at a peak. Here, we gather all suitable ideas and solutions that we come up with during previous phases and apply them to create an initial prototype which we test with targeted groups in order to determine which implementations are destined for success in order to adopt them for future iterations.",
        "User Experience and Interface Design":
          "User Experience and Interface Design",
        "User Experience and Interface Design description":
          "We transform the initial samples into a finalized design during this phase, assuring its release based on the finest design practices worldwide, making it a design worth lingering at. During the end of this phase, a complete series of folders regarding design and user experience is published.",
        Development: "Development",
        "Development description":
          "During this stage we define our development plan, build the database, and develop the entire system across all platforms (web, IOS, Android) and we make sure that only the latest technologies and certified methods regarding development are used during this stage in order to assure high quality.",
        "Quality Assurance": "Quality Assurance",
        "Quality Assurance description":
          "After commencing all the design and development processes regarding all systems, we implement intensive quality assurance methods in order to insure a problem free system.",
        Delivery: "Delivery",
        "Delivery description":
          "Last but not least, we hand over the system to the client and release it legitimately for usage and make sure it’s smooth and free of any bugs.",
        //
        "Our Clients": "Our Clients",
        Jarir: "Jarir",
        "Jarir description":
          "One of the largest listed joint stock companies in the Kingdom of Saudi Arabia. We’ve joined forces with Jarir’s team by conducting a complete study regarding the user experience and redesigned the interface of the app and online store.",
        Mrsool: "Mrsool",
        "Mrsool description":
          "Mrsool for delivery offers an easy, affordable, and not to mention, enjoyable service. We’ve conducted a study on user behavior patterns whilst using Mrsool before designing separate apps for users and messengers.",
        Sarahah: "Sarahah",
        "Sarahah description":
          "Sarahah helps you find and accept your flaws and advantages by receiving constructive criticism from your employees and friends anonymously. We designed and developed the second and third versions of the app completely and the number of Sarahah users reached more than a hundred million.",
        "National Volunteer Portal": "National Volunteer Portal",
        "National Volunteer Portal description":
          "The Ministry of Labor and Social Development seeks to achieve the goals of the National Transformation Program 2020 based on the Kingdom's Vision 2030 by providing a national volunteer portal serving all individuals in the Kingdom, especially volunteers. We have designed and developed the entire portal as well as applications and a website for volunteers.",
        "General Authority for Tourism and National Heritage":
          "General Authority for Tourism and National Heritage",
        "General Authority for Tourism and National Heritage description":
          "We’ve made it easier for investors to gain tourism licenses in Saudi Arabia by developing regulations in cooperation with the Authority’s team.",
        Musaned: "Musaned",
        "Musaned description":
          "Musaned is an electronic platform and a new and integrated system that facilitates recruitment procedures in an unprecedented manner. We have teamed up with a Musaned’s team to design the user experience and app interfaces, and we have also developed user-specific applications.",
        Sawah: "Sawah",
        "Sawah description":
          "We’re well aware of your taste at Sawah, so we seek to redefine cities based on it by changing their entire appearance alongside all its facilities. In a world covered by two thirds of water, Sawah covers the rest.  In collaboration with the Sawah team, we studied user behavior and designed the new application’s user experience and interfaces based on our research.",

        //
        Staff: "Our team",
        "About Staff":
          "Our team is composed of software engineers, creative designers, and other dedicated individuals who place quality in the forefront of any given project prior to releasing it to the masses.",
        //
        Aqwas: "Aqwas",
        "Contact with us": "Contact us",
        "The Company": "Company",
        Language: "العربية",
        lang: "ar",
        //
        "Project field": "Project field",
        "Project type": "Project type",
        "Contact info": "Contact info",
        Website: "Website",
        "Mobile App": "Mobile App",
        "Technical consultations": "Technical consultations",
        "Interface design": "Interface design",
        "Another type": "Another type",
        "Another field": "Another field",
        Next: "Next",
        Islami: "Islami",
        Commercial: "Commercial",
        Entertaining: "Entertaining",
        Educational: "Educational",
        Food: "Food",
        Game: "Game",
        Social: "Social",
        Personal: "Personal",
        Name: "Name",
        "Mobile number": "Mobile number",
        "Country code": "Country Code",
        Email: "Work email",
        Employer: "Company/Employer",
        Description: "Brief description about the project",
        Mr: "Mr",
        Ms: "Ms",
        "Please enter your name": "Please enter your name",
        "Please enter employer": "Please enter employer",
        "Mobile number is invalid": "Mobile number is invalid",
        "Code invalid": "Code is invalid",
        "Work email is invalid": "Please enter work email",
        "Email is invalid": "Email is invalid",
        "Please enter description": "Please enter description",
        Send: "Send",
        "Your request has been sent successfully and you will be replied soon":
          "Your request has been sent successfully and you will be replied soon",
        ProjectFor: "Project for",
        Company: "Company",
        Individual: "Individual",
        Government: "Government",
        Required: "Required",
        Budget: "Project Size",
        Budget1: "Small (200,000 - 400,000 SAR)",
        Budget2: "Medium (400,000 - 600,000 SAR)",
        Budget3: "Large (600,000 - Million SAR)",
        Budget4: "Huge (More than a Million SAR)",
        //
        //
        Homepage: "Homepage",
        "About Aqwas": "About Aqwas",
        About: "About",
        //
        Body: `
        <html>
        <p>Dear client,</p>
        <p>We provide dedicated services for institutions and projects had reached an advanced stage of the growth of the market. Therefore, it may not be the most suitable option for individuals and recent projects.</p>
        <p>
        If you are an individual or representative of a recent project, we apologize because our services are not corresponding to your requirements. We suggest that you review the following software development company guide:‫
        </p>
        <br/>
        <p>
        ‬‬We hope you find something that fits your requirements, and will contribute to your success.‬
        </p>
        <p>Regards,</p>
        </html>`,
      },
    },
  },
  fallbackLng: ["ar", "en"],
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
